import { Button } from 'components/partials';
import { useTheme } from 'hooks';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { Translate } from 'react-localize-redux';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 200;
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const CenterContainer = styled.div`
  min-height: 60%;
  width: 25rem;
  background: #FFF;
  border-radius: 0.625rem;
`;

const Textarea = styled.textarea`
  margin-top: 0.875rem;
  margin-left: 0.875rem;
  margin-right: 0.875rem;
  margin-bottom: 0.313rem;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;
  height: 8rem;
  padding-top: 0.375rem;
  padding-left: 0.625rem;
  font-size: 1.125rem;
  resize: none;
  width: 23.188rem;
`;

const Buttons = styled.div`
  display: flex
  margin-left: 0.875rem;
  margin-right: 0.875rem;

  button:first-child {
    margin-right: 0.75rem;
  }

  button {
    min-width: 6rem !important;
    max-width: 11.875rem !important;
  }
`;

type CommentProps = {
  comment: string;
  show: boolean;
  onSave: (comment: string) => void;
  onCancel: () => void;
}

const Comment: React.FC<CommentProps> = ({ show, comment, onSave, onCancel }) => {
  const { styles } = useTheme();
  const [commentValue, setCommentValue] = useState(comment);

  useEffect(() => setCommentValue(comment), [comment]);

  if (!show) return null;

  return (
    <Translate>
      {({ translate }) => (
        <Wrapper>
          <CenterContainer>
            <Textarea
              name="comment"
              value={commentValue}
              placeholder={translate(TRANSLATIONS_VALUES_KEYS.vehicle_scan.add_comment_text) as string}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCommentValue(e.target.value)}
              data-testid="comment"
            />
            <Buttons>
              <Button
                role="button"
                testId="saveComment"
                bg={styles.colorPrimaryButtonBackground}
                color={styles.colorPrimaryButtonText}
                hoverBorderColor={styles.colorPrimaryButtonBackgroundDark}
                hoverShadowColor={styles.colorPrimaryButtonBackgroundLight}
                onClick={() => onSave(commentValue)}
              >
                Save
              </Button>
              <Button
                role="button"
                testId="saveComment"
                bg={styles.colorSecondaryButtonBackground}
                color={styles.colorSecondaryButtonText}
                hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                onClick={() => {
                  setCommentValue(comment);
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </Buttons>
          </CenterContainer>
        </Wrapper>
      )}
    </Translate>
  );
};

export default Comment;
