/* Requirement: UrlId (access token) is always 24 characters! */
export const parseUrlId = (urlId: string | string [] | undefined) : string | undefined => {
  if (!urlId) return undefined;
  if (Array.isArray(urlId)) throw Error('Invalid urlId');
  return urlId.substring(0, 24);
};

/* Requirement: Lang is always 2 characters! */
export const parseLang = (lang: string | string[]) : string | undefined => {
  if (!lang) return undefined;
  if (Array.isArray(lang)) throw Error('Invalid lang');
  return lang.substring(0, 2).toLowerCase();
};
